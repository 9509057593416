import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from 'react-router-dom';
import './index.css';
import Playground from './Playground';
import Homepage from './Homepage';
//import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/verifx/playground",
    element: <Playground />,
  },
  {
    path: "/verifx",
    element: <Homepage />,
  },
  {
    path: "/",
    element: <Homepage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />);
/*
  <React.StrictMode>
    <Playground />
  </React.StrictMode>
);
 */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
