//import logo from './logo.svg';
import React from 'react';
import './App.css';

import CodeEditor from '@uiw/react-textarea-code-editor';
import { CButton, CSpinner } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import rehypePrism from "rehype-prism-plus";
import rehypeRewrite from "rehype-rewrite";
import GitHubCorners from '@uiw/react-github-corners';
import '@wcj/dark-mode';

// TODO: to run in dev mode (reloads automatically on change): npm start
//.w-tc-editor --color-fg-default

//document.body.style.backgroundColor =

const spitterEndpoint = 'http://spitter.soft.vub.ac.be/verify/'
const localEndpoint = 'http://localhost:3001/verify/'

const keywords = new Set([ "proof", "forall", "exists" ])

function Playground() {
  const [disabled, setDisabled] = React.useState(false);
  const [code, setCode] = React.useState(
    `object MyFirstProof {\n  proof incorrectProof[T] {\n    forall (a: T, b: T) {\n      a == b\n    }\n  }\n}`
  );
  const [buttonText, setButtonText] = React.useState('Verify my program!')

  const [result, setResult] = React.useState("")

  const requestVerification = async () => {
    const response = await fetch(spitterEndpoint, {
      method: 'POST',
      body: JSON.stringify({ program: code }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      return await response.json();
    }
    else if (response.status === 502) {
      throw new Error('Could not reach the verification server.')
    }
    else {
      throw new Error(response.statusMessage)
    }
  }

  const verify = async (e) => {
    // Disable the button until we get the verification results
    setDisabled(true);
    setButtonText('  Verifying...')

    try {
      // Make an HTTP request to the server
      const response = await requestVerification();

      if (response.error) {
        setResult('VeriFx returned an error:\n' + response.error)
      }
      else {
        if (!response.proofs) {
          setResult('Unexpected response from the server. Verification output is missing.')
        }
        else {
          const proofs = response.proofs
          const prettyPrintedProofs = proofs.map(proof => {
            const objectName = proof.objectName
            const proofName = proof.proofName
            const outcome = proof.outcome.status
            const counterExample = proof.outcome.counterExample ? `Counterexample:\n${proof.outcome.counterExample}\n` : ''
            return `=== Proof ${proofName} in ${objectName} ===\nOutcome: ${outcome}\n${counterExample}`
          }).join('\n')

          // then show the response
          setResult(prettyPrintedProofs);
        }
      }
    } catch (error) {
      setResult('Oops... something went wrong: ' + error.message)
    } finally {
      // re-enable the button
      setDisabled(false);
      setButtonText('Verify my program!')
    }
  }

  const vars = {
    'padding-left': 10,
    'padding-top': 5
  }

  const vars2 = {
    'padding-left': 10,
    'padding-top': 15
  }

  return (
    <div className="App wmde-markdown-var">
      <dark-mode dark="Dark" light="Light" style={{ position: 'fixed', top: 8, left: 10 }}></dark-mode>
      <GitHubCorners fixed href="https://github.com/verifx-prover/verifx" />
      <h1 className="App-title">
        VeriFx Playground
      </h1>
      <p className="info">
        Welcome to the VeriFx playground!<br />
        Write VeriFx programs in the editor below and click the verify button to check your proofs 🚀.
        Depending on the program, verification may take several seconds.
        A tutorial and the language reference can be found <a href={'verifx-prover.github.io/verifx/'}>here</a>.
      </p>
      <CodeEditor
        className="App-test-case"
        value={code}
        language="scala"
        placeholder="Please enter VeriFx code."
        onChange={(evn) => setCode(evn.target.value)}
        padding={15}
        rehypePlugins={[
          [rehypePrism, { ignoreMissing: true }],
          [
            rehypeRewrite,
            {
              rewrite: (node, index, parent) => {
                const doRewrite = (node) => {
                  if (node.properties?.className?.includes("code-line")) {
                    const highlightKeywords = (node) => {
                      if (node.type === "text") {
                        const words = node.value.split(/(\s+)/)
                        const highlightedWords = words.map(word => {
                          if (keywords.has(word)) {
                            // highlight it
                            return {
                              type: 'element',
                              tagName: 'span',
                              properties: {
                                className: [ 'token', 'keyword' ]
                              },
                              children: [ {
                                ...node,
                                value: word
                              } ]
                            }
                          }
                          else {
                            return { type: 'text', value: word }
                          }
                        })
                        return highlightedWords //{ type: 'span', children: highlightedWords }
                      }
                      else {
                        return node
                      }
                    }
                    node.children = node.children.map(highlightKeywords).flat()
                    return node
                  }
                  else {
                    if (node.children && Array.isArray(node.children)) {
                      node.children = node.children.map(doRewrite)
                    }
                    return node
                  }
                }

                doRewrite(node)
              }
            }
          ]
        ]}
        style={{
          fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
        }}
      />
      <div style={vars} className="info">
        <CButton disabled={disabled} onClick={verify}>
          {disabled && <CSpinner loading={disabled} component="span" size="sm" aria-hidden="true" spinner="false"/>}
          {buttonText}
        </CButton>
      </div>
      <div style={vars2} className="App-test-case">
        Verification output:
      </div>
      <CodeEditor
        className="App-test-case"
        value={result}
        language="scala"
        placeholder="No verification results yet."
        onChange={(evn) => setResult(evn.target.value)}
        padding={15}
        style={{
          fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
        }}
      />
    </div>
  );
}

export default Playground;
