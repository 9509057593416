import React from 'react';
import './App.css';
import logo from './logo192.png';

import CodeEditor from '@uiw/react-textarea-code-editor';
import { CButton, CSpinner } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import rehypePrism from "rehype-prism-plus";
import rehypeRewrite from "rehype-rewrite";
import GitHubCorners from '@uiw/react-github-corners';
import '@wcj/dark-mode';
import {
  Link,
} from "react-router-dom";

// TODO: to run in dev mode (reloads automatically on change): npm start

const sampleProof =
`proof incorrectProof[T] {
  forall (a: T, b: T) {
    a == b
  }
}`
const sampleCounterExample =
`enum T {
  T_val_0 | T_val_1
}
val a: T = T_val_0
val b: T = T_val_1
`
const keywords = new Set([ "proof", "forall", "exists" ])

function Homepage() {
  const [code, setCode] = React.useState(sampleProof);
  const [_counterexample, setCounterExample] = React.useState(sampleProof);

  return (
    <div className="App wmde-markdown-var">
      <dark-mode dark="Dark" light="Light" style={{ position: 'fixed', top: 8, left: 10 }}></dark-mode>
      <GitHubCorners fixed href="https://github.com/verifx-prover/verifx" />
      <h1 className="App-title">
        <img src={logo} className="App-logo" alt="VeriFx logo" />
        VeriFx
      </h1>
      <p className="Homepage-info">
        Verify program correctness <b>automatically </b> and <b>seamlessly</b>.<br />
        Define the correctness properties and off you go 🚀
      </p>

      <div className="table" style={{color: 'inherit'}}>
        <tr>
          <p className="sample-explanation">
            <i>Property (input)</i>
          </p>
          <p className="sample-explanation">
            <i>Counterexample (output)</i>
          </p>
        </tr>
        <tr>

      <CodeEditor
        className="App-sample-proof"
        value={code}
        language="scala"
        onChange={(evn) => setCode(sampleProof)}
        padding={15}
        rehypePlugins={plugin}
        style={{
          fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
        }}
      />

      <CodeEditor
        className="App-sample-proof"
        value={sampleCounterExample}
        language="scala"
        onChange={(evn) => setCounterExample(sampleCounterExample)}
        padding={15}
        rehypePlugins={plugin}
        style={{
          fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
        }}
      />
          </tr>
        <tr>
          <th>
              <a href="http://verifx-prover.github.io/verifx" target="_blank">
        <CButton className="App-sample-proof" style={{float: 'right', "margin-top": "10px"}} shape="rounded-pill">
          {"Documentation"}
        </CButton>
            </a>
          </th>
          <th>
        <Link to="/verifx/playground">
          <CButton className="App-sample-proof" style={{float: 'left', "margin-top": "10px"}} shape="rounded-pill">
            {"Playground"}
          </CButton>
        </Link>
            </th>
        </tr>
      </div>

    </div>
  );
}

const vars = {
  'padding-left': 10,
  'padding-top': 5,
}

const plugin = [
  [rehypePrism, { ignoreMissing: true }],
  [
    rehypeRewrite,
    {
      rewrite: (node, index, parent) => {
        const doRewrite = (node) => {
          if (node.properties?.className?.includes("code-line")) {
            const highlightKeywords = (node) => {
              if (node.type === "text") {
                const words = node.value.split(/(\s+)/)
                const highlightedWords = words.map(word => {
                  if (keywords.has(word)) {
                    // highlight it
                    return {
                      type: 'element',
                      tagName: 'span',
                      properties: {
                        className: [ 'token', 'keyword' ]
                      },
                      children: [ {
                        ...node,
                        value: word
                      } ]
                    }
                  }
                  else {
                    return { type: 'text', value: word }
                  }
                })
                return highlightedWords //{ type: 'span', children: highlightedWords }
              }
              else {
                return node
              }
            }
            node.children = node.children.map(highlightKeywords).flat()
            return node
          }
          else {
            if (node.children && Array.isArray(node.children)) {
              node.children = node.children.map(doRewrite)
            }
            return node
          }
        }

        doRewrite(node)
      }
    }
  ]
]

export default Homepage;
